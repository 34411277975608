import styled from 'styled-components'

export const Container = styled.div`
  background: linear-gradient(112.58deg, #f7eb9e 10.11%, #b3edff 96.08%);
  ${({ theme }) => theme.mixins.H5};
  box-shadow: 1px 3px 27px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

export const DiscountTag = styled.div`
  position: absolute;
  padding: 4.5px 13px;
  background: #fff;
  border-radius: 30px;
  top: 5%;
  right: 5%;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  z-index: 2;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-size: 9px;
    line-height: 120%;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H6};
  }
`

export const Header = styled.div`
  margin: 10px 13px 8px 13px;
  display: flex;
  flex-direction: column;
  height: 117px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin: 18px 0 13px 15px;
    height: 87px;
    max-width: 205px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 180px;
    margin: 30px 0 12px 24px;
    height: 160px;
  }
`

export const Image = styled.div<{ imagePath: string | null }>`
  display: ${({ imagePath }) => (imagePath ? 'block' : 'none')};
`

export const HeaderBottom = styled.div`
  margin-top: auto;
  z-index: 2;
`

export const DiscountFullPrice = styled.div`
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mixins.H6};

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-weight: 700;
    font-size: 11.8954px;
    line-height: 120%;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H5};
  }
`

export const FullPrice = styled.div`
  text-decoration: line-through;
  color: #757575;
  ${({ theme }) => theme.mixins.text4};
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-weight: 400;
    font-size: 8px;
    line-height: 140%;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.text3};
  }
`

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mixins.text5};
  margin-bottom: 5px;
  z-index: 2;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-weight: 400;
    font-size: 8px;
    line-height: 140%;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.text3};
  }
`

export const Title = styled.h4`
  ${({ theme }) => theme.mixins.H6};
  color: ${({ theme }) => theme.colors.black};
  max-width: 152px;
  z-index: 2;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 143px;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 240px;
    ${({ theme }) => theme.mixins.H4};
  }
`
export const Or = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  position: absolute;
  top: -35%;
  left: 50%;
  transform: translate(-50%, 35%);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 30px;
  background: #ffffff;
  box-shadow: -3px 2px 23px rgba(0, 0, 0, 0.05);
  border-radius: 25px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-size: 12px;
    line-height: 120%;
    top: -38%;
    left: 50%;
    transform: translate(-50%, 30%);
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    top: -34%;
    ${({ theme }) => theme.mixins.H5};
  }
`

export const Bottom = styled.div`
  background: #fff;
  padding: 13px 13px 5px 13px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: auto;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 6px 15px 12px 15px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 9px 23px 20px 23px;
    ${({ theme }) => theme.mixins.text3};
  }
`
export const PriceInformation = styled.div``

export const MonthPriceRow = styled.div`
  ${({ theme }) => theme.mixins.text4};
  display: flex;
  align-items: center;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-weight: 400;
    font-size: 9px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    font-size: 16px;
  }
`
export const MonthPrice = styled.span`
  color: ${({ theme }) => theme.colors.black};
  margin-right: 5px;
  ${({ theme }) => theme.mixins.H5};
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-weight: 700;
    font-size: 14px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H4};
  }
`

export const PriceDescription = styled.div`
  color: #a3a3a3;
  ${({ theme }) => theme.mixins.text4};
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-weight: 400;
    font-size: 8px;
    line-height: 140%;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.text3};
  }
`

export const Button = styled.div`
  background: #fff;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  flex-shrink: 0;
  align-self: end;
  display: flex;
  align-items: center;
  justify-content: center;
  path {
    stroke: ${({ theme }) => theme.colors.primary};
  }
  width: 28px;
  height: 28px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 23px;
    height: 23px;

    svg {
      width: 10px;
      height: 10px;
    }
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 38px;
    height: 38px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`

import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import getConfig from 'next/config'
/**
 * ui
 */
import Icon from '../Icon'
import * as Styled from './styled'
import ModuleCard from './ModuleCard'
/**
 * types
 */
import { ACCESS_TYPE } from 'src/api/product'

const {
  publicRuntimeConfig: { STATIC_URL },
} = getConfig()

interface Style {}

interface Props extends Style {
  course: ModuleCard
}

const ModuleCardView: React.FC<Props> = ({ course }) => {
  const { title, category, slug } = course._course

  return (
    <Link href={`/${category.slug}/${slug}`} passHref>
      <Styled.Container>
        <ModuleCardImageView course={course} />
        <ModuleCardDiscountTagView course={course} />
        <Styled.Header>
          <Styled.Subtitle>{category.title}</Styled.Subtitle>
          <Styled.Title>{title}</Styled.Title>

          <ModuleCardHeaderBottomView course={course} />
        </Styled.Header>
        <Styled.Bottom>
          <ModuleCardOrView course={course} />
          <ModuleCardPriceInformationView course={course} />

          <ModuleCardFreePriceInformationView course={course} />

          <ModuleCardPremiumPriceInformationView course={course} />
          <Styled.Button>
            <Icon name='arrow-right' width='16' height='16' />
          </Styled.Button>
        </Styled.Bottom>
      </Styled.Container>
    </Link>
  )
}

const ModuleCardImageView: React.FC<Props> = ({ course }) => {
  const { imagePath } = course._course

  return imagePath ? (
    <Image
      src={`${STATIC_URL}/${imagePath}`}
      quality={100}
      layout='fill'
      alt='background'
      objectFit='cover'
      priority
    />
  ) : null
}

const ModuleCardDiscountTagView: React.FC<Props> = ({ course }) => {
  const { fullPayDiscountPercent } = course._course
  return fullPayDiscountPercent ? (
    <Styled.DiscountTag>-{fullPayDiscountPercent}%</Styled.DiscountTag>
  ) : null
}

const ModuleCardHeaderBottomView: React.FC<Props> = ({ course }) => {
  const { t } = useTranslation('common')
  const { fullPrice } = course._course

  return course.isPayable ? (
    <Styled.HeaderBottom>
      <Styled.DiscountFullPrice>
        {t('intlNumber', { val: course.isPayable.course })} ₽
      </Styled.DiscountFullPrice>
      <Styled.FullPrice>
        {t('intlNumber', { val: fullPrice / 100 })} ₽ за курс
      </Styled.FullPrice>
    </Styled.HeaderBottom>
  ) : null
}

const ModuleCardOrView: React.FC<Props> = ({ course }) => {
  const { accessType } = course._course
  return ACCESS_TYPE.ACCESS_TYPE_PAY === accessType ? (
    <Styled.Or>или</Styled.Or>
  ) : null
}

const ModuleCardPriceInformationView: React.FC<Props> = ({ course }) => {
  const { t } = useTranslation('common')
  const { accessType, initialAmounts } = course._course

  return ACCESS_TYPE.ACCESS_TYPE_PAY === accessType ? (
    <Styled.PriceInformation>
      {initialAmounts && 'month' in initialAmounts && (
        <Styled.MonthPriceRow>
          <Styled.MonthPrice>
            от {t('intlNumber', { val: initialAmounts.month })} ₽{' '}
          </Styled.MonthPrice>{' '}
          / месяц
        </Styled.MonthPriceRow>
      )}
      <Styled.PriceDescription>
        в рассрочку до 36 мес / без переплат
      </Styled.PriceDescription>
    </Styled.PriceInformation>
  ) : null
}

const ModuleCardFreePriceInformationView: React.FC<Props> = ({ course }) => {
  const { accessType } = course._course

  return ACCESS_TYPE.ACCESS_TYPE_FREE === accessType ? (
    <Styled.PriceInformation>
      <Styled.MonthPrice>Бесплатно</Styled.MonthPrice>
      <Styled.PriceDescription>для всех пользователей</Styled.PriceDescription>
    </Styled.PriceInformation>
  ) : null
}

const ModuleCardPremiumPriceInformationView: React.FC<Props> = ({ course }) => {
  const { accessType } = course._course

  return ACCESS_TYPE.ACCESS_TYPE_PREMIUM === accessType ? (
    <Styled.PriceInformation>
      <Styled.MonthPrice>Бесплатно</Styled.MonthPrice>
      <Styled.PriceDescription>для “Премиум”</Styled.PriceDescription>
    </Styled.PriceInformation>
  ) : null
}

export default ModuleCardView

import { Product, ACCESS_TYPE, TInitialAmounts } from 'src/api/product'
import ModuleCardView from './ModuleCardView'

class ModuleCard {
  _course: Product
  constructor(product: Product) {
    this._course = product
    this.formatAmounts()
  }
  get initialAmounts() {
    return this._course.initialAmounts
  }

  get checkInitialAmountsKey() {
    return 'initialAmounts' in this._course
  }

  get isPayable() {
    const { accessType, fullPayDiscountPercent, initialAmounts } = this._course
    if (
      ACCESS_TYPE.ACCESS_TYPE_PAY === accessType &&
      fullPayDiscountPercent &&
      this.checkInitialAmountsKey
    ) {
      return initialAmounts
    }
    return false
  }

  formatAmounts = () => {
    if (this._course.initialAmounts) {
      const formatted: TInitialAmounts = {}
      ;(Object.keys(this._course.initialAmounts) as Array<string>).forEach(
        type => {
          if (type === 'course' && this._course.initialAmounts) {
            formatted['month'] = Number(
              (this._course.initialAmounts[type] / 100 / 36).toFixed(0),
            )
          }
          if (this._course.initialAmounts) {
            formatted[type] = this._course.initialAmounts[type] / 100
          }
        },
      )
      this._course = {
        ...this._course,
        initialAmounts: formatted,
      }
    }
  }
  render = (slug: string) => {
    return <ModuleCardView key={slug} course={this} />
  }
}
export default ModuleCard
